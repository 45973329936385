import React from "react";
import {Link} from "react-router-dom";
import classes from "./Button.module.css";

export default function Button({variant = "primary", to, children, ...props}: any) {
  const cx = [classes.button];
  const cxInner = [];
  if (props.className) {
    cx.push(props.className);
  }
  switch (variant) {
    case "primary": {
      cx.push("color-blue-5");
      cxInner.push("color-white");
      break;
    }
    case "secondary": {
      cx.push("color-blue-3");
      cxInner.push("color-blue-5");
      break;
    }
    case "danger": {
      cx.push("color-red-3");
      cxInner.push("color-red-5");
      break;
    }
  }

  return to ? (
    <Link {...props} to={to} className={cx.join(" ")}>
      <span className={cxInner.join(" ")}>{children}</span>
    </Link>
  ) : (
    <button {...props} className={cx.join(" ")}>
      <span className={cxInner.join(" ")}>{children}</span>
    </button>
  );
}
