import React from "react";
import {connect} from "react-redux";
import querystring from "querystring";
import Layout from "../components/Layout";
import CircleView from "../components/CircleView";
import NavBar from "../components/NavBar";
import {RouteMatch, Routes} from "../components/Routes";
import {Circle, CirclesState, getCircle, loadCircles, getCurrentUser, User} from "../../../shared";

interface Props {
  match: RouteMatch;
  circle?: Circle;
  loadCircles: any;
  history: any;
  location: any;
  user?: User;
}

class CirclePage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    if (!props.circle) {
      props.loadCircles(props.match.params.id);
    }
  }

  getTitle = () => {
    if (this.props.circle) {
      return `Circle - ${this.props.circle.name}`;
    } else if (this.props.match.params.id === "new") {
      return "New Circle";
    }
    return "Circle";
  };

  render() {
    // This will be used for editing circles only (I think?)
    const {lastViewed} = querystring.parse(this.props.location.search.slice(1));
    return (
      <Layout
        title={this.getTitle()}
        navbar={
          <NavBar>
            {this.props.user && this.props.user.kind === "superAdmin" ? (
              <NavBar.LogoHeading />
            ) : (
              <>
                <NavBar.DetailHeading to={Routes.Users}>
                  <span className="color-black">user's name</span>
                </NavBar.DetailHeading>
                <NavBar.Tabs
                  tabs={[{name: "Activity"}, {name: "Chat", selected: true}, {name: "Info"}]}
                />
              </>
            )}
          </NavBar>
        }
      >
        <CircleView
          newCircle={this.props.match.params.id === "new"}
          circle={this.props.circle}
          history={this.props.history}
          lastViewedUser={lastViewed as string | undefined}
        />
      </Layout>
    );
  }
}

export default connect(
  (state: any, ownProps: Props) => ({
    circle: getCircle(state, ownProps.match.params.id),
    user: getCurrentUser(state),
  }),
  {
    loadCircles,
  }
)(CirclePage as any);
