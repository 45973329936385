import React from "react";
import ReactDOM from "react-dom";
import Raven from "raven-js";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./assets/normalize.css";
import "./assets/milligram.min.css";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));

Raven.config("https://d3017f8da1c74aa6928c9e237f4d5138@sentry.io/1453745").install();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
