import React, {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PageHeader from "./PageHeader";
import SearchBar from "./SearchBar";
import {selectCenters, loadCenters} from "../store/Centers";
import {Center} from "../../../shared";

interface CentersProps {
  centers: any[];
  loadCenters: () => void;
}

function _CenterList(props: CentersProps) {
  const [editing, _setEditing] = useState(undefined);
  const [searchTerm, _setSearchTerm] = useState("");
  return (
    <>
      <PageHeader action="Add new" actionTo="/centers/new">
        Centers
      </PageHeader>

      <SearchBar value={searchTerm} onChange={(e: any) => _setSearchTerm(e.target.value)} />

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {props.centers.map((center: Center) => (
            <tr key={center._id}>
              <td className="fs-18">
                <Link to={"/centers/" + center._id}>{center.name}</Link>
              </td>
              <td>
                <span className="o-50">{center.address}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

const CenterList = connect(
  (state: any) => ({
    centers: selectCenters(state),
  }),
  {
    loadCenters,
  }
)(_CenterList);

export default CenterList;
