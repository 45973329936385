import {takeEvery, call} from "redux-saga/effects";
import {AppActions} from "../store/App";
import {bootstrapAuthAction} from "./auth";

function* bootstrapAppAction() {
  yield call(bootstrapAuthAction as any);
  return undefined;
}

export function* bootstrapAppSaga() {
  yield takeEvery(AppActions.BOOTSTRAP_APP, bootstrapAppAction);
}
