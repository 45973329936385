import React from "react";
import classNames from "classnames";
import classes from "./PageHeader.module.css";
import Button from "./Button";

interface Props {
  children: any;
  action?: string;
  actionTo?: string;
  actionOnClick?: () => void;
}

export default function PageHeader(props: Props) {
  return (
    <div className="clearfix">
      <h1 className="float-left">{props.children}</h1>
      {props.action && (
        <Button
          variant="secondary"
          className={classNames("float-right", classes.button)}
          to={props.actionTo}
          onClick={props.actionOnClick}
        >
          {props.action}
        </Button>
      )}
    </div>
  );
}
