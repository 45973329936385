import React from "react";
import DocumentTitle from "react-document-title";
import classNames from "classnames";

interface Props {
  children: any;
  title?: string;
  navbar: any;
  className?: string;
}

const Layout: React.FC<Props> = (props: Props) => {
  const {children, title, navbar} = props;
  return (
    <DocumentTitle title={`FlightDeck${title ? " - " + title : ""}`}>
      <>
        {navbar}
        <div className={classNames("container", props.className)}>
          <main>{children}</main>
        </div>
      </>
    </DocumentTitle>
  );
};

export default Layout;
