"use strict";

var _objectSpread = require("/opt/build/repo/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var UserStore_1 = require("./UserStore");

var SocketsStore_1 = require("./SocketsStore");

var MessagesActionTypes;

(function (MessagesActionTypes) {
  MessagesActionTypes["FETCH_MESSAGES"] = "@@messages/FETCH_MESSAGES";
  MessagesActionTypes["MERGE_MESSAGES"] = "@@messages/MERGE_MESSAGES";
  MessagesActionTypes["MESSAGES_LOADED"] = "@@messages/MESSAGES_LOADED";
  MessagesActionTypes["LOADING_ERROR"] = "@@messages/LOADING_ERROR";
  MessagesActionTypes["CREATE_MESSAGE"] = "@@messages/CREATE_MESSAGE";
  MessagesActionTypes["CREATE_MESSAGE_ATTACHMENT"] = "@@messages/CREATE_MESSAGE_ATTACHMENT";
  MessagesActionTypes["UPDATE_MESSAGE"] = "@@messages/UPDATE_MESSAGE";
  MessagesActionTypes["DELETE_MESSAGE"] = "@@messages/DELETE_MESSAGE";
  MessagesActionTypes["CRUD_DONE"] = "@@messages/CRUD_DONE";
  MessagesActionTypes["CRUD_ERROR"] = "@@messages/CRUD_ERROR";
  MessagesActionTypes["CLEAR_CRUD_ERRORS"] = "@@messages/CLEAR_CRUD_ERRORS";
})(MessagesActionTypes = exports.MessagesActionTypes || (exports.MessagesActionTypes = {}));
/**
 * Reducer
 */


exports.initialMessagesState = {
  messages: {},
  circles: {},
  loading: false,
  errors: undefined,
  crudLoading: false,
  crudErrors: undefined
};

function messagesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.initialMessagesState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case MessagesActionTypes.FETCH_MESSAGES:
      {
        return _objectSpread({}, state, {
          loading: true
        });
      }

    case SocketsStore_1.SocketsActionTypes.CREATE_MESSAGE: //case SocketsActionTypes.CREATE_MESSAGE_ATTACHMENT:

    case MessagesActionTypes.CREATE_MESSAGE:
    case MessagesActionTypes.CREATE_MESSAGE_ATTACHMENT:
    case MessagesActionTypes.UPDATE_MESSAGE:
      return _objectSpread({}, state, {
        crudLoading: true
      });

    case MessagesActionTypes.MESSAGES_LOADED:
      {
        return _objectSpread({}, state, {
          loading: false,
          messages: action.payload.messages,
          circles: action.payload.circles
        });
      }

    case MessagesActionTypes.LOADING_ERROR:
      {
        return _objectSpread({}, state, {
          loading: false,
          errors: action.payload
        });
      }

    case MessagesActionTypes.CRUD_ERROR:
      {
        return _objectSpread({}, state, {
          crudErrors: action.payload
        });
      }

    case MessagesActionTypes.CRUD_DONE:
      {
        return _objectSpread({}, state, {
          crudLoading: false,
          crudErrors: undefined
        });
      }

    case MessagesActionTypes.CLEAR_CRUD_ERRORS:
      {
        return _objectSpread({}, state, {
          crudErrors: undefined
        });
      }

    case UserStore_1.UserActionTypes.LOG_OUT:
      {
        return exports.initialMessagesState;
      }

    default:
      return state;
  }
}

exports.messagesReducer = messagesReducer;
/**
 * Actions
 */

exports.loadMessages = function () {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return {
    type: MessagesActionTypes.FETCH_MESSAGES,
    payload: {
      page: page
    }
  };
};
/**
 * NOTE! Create messagea and Create Message Attachment action has some special behavior!
 *
 * If initialized, socketsMiddleware will intercept these actions and handle
 * them using sockets, instead of letting messagesUpsertSaga handle it with XHR.
 *
 * If you see SocketsActionTypes.CREATE_MESSAGE in redux, you know this happened.
 */


exports.createMessage = function (payload) {
  return {
    type: MessagesActionTypes.CREATE_MESSAGE,
    payload: payload
  };
};

exports.createMessageWithAttachment = function (payload) {
  return {
    type: MessagesActionTypes.CREATE_MESSAGE_ATTACHMENT,
    payload: payload
  };
};

exports.updateMessage = function (payload) {
  return {
    type: MessagesActionTypes.UPDATE_MESSAGE,
    payload: payload
  };
};

exports.deleteMessage = function (id) {
  return {
    type: MessagesActionTypes.DELETE_MESSAGE,
    payload: id
  };
};

exports.mergeMessages = function (messages) {
  return {
    type: MessagesActionTypes.MERGE_MESSAGES,
    payload: messages
  };
};

exports.messagesLoaded = function (messagesMap, messagesCirclesMap) {
  return {
    type: MessagesActionTypes.MESSAGES_LOADED,
    payload: {
      messages: messagesMap,
      circles: messagesCirclesMap
    }
  };
};

exports.clearMessagesCrudErrors = function () {
  return {
    type: MessagesActionTypes.CLEAR_CRUD_ERRORS
  };
};
/**
 * Selectors
 */


exports.getMessagesFor = function (state, circleId) {
  if (!state.messages.circles[circleId]) {
    return [];
  }

  return state.messages.circles[circleId].map(function (messageId) {
    return state.messages.messages[messageId];
  });
};

exports.getMessage = function (state, messageId) {
  return state.messages.messages[messageId];
};

exports.getMessages = function (state, messageId) {
  return Object.values(state.messages.messages) || [];
};

exports.getMessagesCrudErrors = function (state) {
  return state.messages.crudErrors;
};

exports.getMessagesMap = function (state) {
  return state.messages.messages;
};

exports.getMessagesCirclesMap = function (state) {
  return state.messages.circles;
};