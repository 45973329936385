"use strict";

var _objectSpread = require("/opt/build/repo/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var UserStore_1 = require("./UserStore");

var EventHandlersActionTypes;

(function (EventHandlersActionTypes) {
  EventHandlersActionTypes["FETCH_EVENTS"] = "@@events/FETCH_EVENTS";
  EventHandlersActionTypes["EVENTS_LOADED"] = "@@events/EVENTS_LOADED";
  EventHandlersActionTypes["LOADING_ERROR"] = "@@events/LOADING_ERROR";
  EventHandlersActionTypes["CREATE_EVENT"] = "@@events/CREATE_EVENT";
  EventHandlersActionTypes["UPDATE_EVENT"] = "@@events/UPDATE_EVENT";
  EventHandlersActionTypes["DELETE_EVENT"] = "@@events/DELETE_EVENT";
  EventHandlersActionTypes["CRUD_DONE"] = "@@events/CRUD_DONE";
  EventHandlersActionTypes["CRUD_ERROR"] = "@@events/CRUD_ERROR";
  EventHandlersActionTypes["CLEAR_CRUD_ERRORS"] = "@@events/CLEAR_CRUD_ERRORS";
  EventHandlersActionTypes["REQUEST_MOTION_PERMISSION"] = "@@events/REQUEST_MOTION_PERMISSION";
  EventHandlersActionTypes["CREATE_DEFAULT"] = "@@events/CREATE_DEFAULT";
  EventHandlersActionTypes["CREATE_DEFAULT_DONE"] = "@@events/CREATE_DEFAULT_DONE";
})(EventHandlersActionTypes = exports.EventHandlersActionTypes || (exports.EventHandlersActionTypes = {}));
/**
 * Reducer
 */


exports.initialEventHandlersState = {
  events: {},
  loading: false,
  errors: undefined,
  crudLoading: false,
  crudErrors: undefined
};

function eventHandlersReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.initialEventHandlersState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case EventHandlersActionTypes.FETCH_EVENTS:
      {
        return _objectSpread({}, state, {
          loading: true
        });
      }

    case EventHandlersActionTypes.CREATE_EVENT:
    case EventHandlersActionTypes.UPDATE_EVENT:
      return _objectSpread({}, state, {
        crudLoading: true
      });

    case EventHandlersActionTypes.EVENTS_LOADED:
      {
        return _objectSpread({}, state, {
          loading: false,
          events: action.payload.events
        });
      }

    case EventHandlersActionTypes.LOADING_ERROR:
      {
        return _objectSpread({}, state, {
          loading: false,
          errors: action.payload
        });
      }

    case EventHandlersActionTypes.CRUD_ERROR:
      {
        return _objectSpread({}, state, {
          crudErrors: action.payload
        });
      }

    case EventHandlersActionTypes.CRUD_DONE:
      {
        return _objectSpread({}, state, {
          crudLoading: false,
          crudErrors: undefined
        });
      }

    case EventHandlersActionTypes.CLEAR_CRUD_ERRORS:
      {
        return _objectSpread({}, state, {
          crudErrors: undefined
        });
      }

    case UserStore_1.UserActionTypes.LOG_OUT:
      {
        return exports.initialEventHandlersState;
      }

    case EventHandlersActionTypes.REQUEST_MOTION_PERMISSION:
      {
        return _objectSpread({}, state, {
          permissionRequestedAt: action.payload
        });
      }

    default:
      return state;
  }
}

exports.eventHandlersReducer = eventHandlersReducer;
/**
 * Actions
 */

exports.loadEventHandlers = function () {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return {
    type: EventHandlersActionTypes.FETCH_EVENTS,
    payload: {
      page: page
    }
  };
};

exports.createEventHandler = function (payload) {
  return {
    type: EventHandlersActionTypes.CREATE_EVENT,
    payload: payload
  };
};

exports.updateEventHandler = function (payload) {
  return {
    type: EventHandlersActionTypes.UPDATE_EVENT,
    payload: payload
  };
};

exports.deleteEventHandler = function (id) {
  return {
    type: EventHandlersActionTypes.DELETE_EVENT,
    payload: id
  };
};

exports.eventHandlersLoaded = function (eventsMap) {
  return {
    type: EventHandlersActionTypes.EVENTS_LOADED,
    payload: {
      events: eventsMap
    }
  };
};

exports.clearEventHandlersCrudErrors = function () {
  return {
    type: EventHandlersActionTypes.CLEAR_CRUD_ERRORS
  };
};

exports.requestMotionPermissions = function (payload) {
  return {
    type: EventHandlersActionTypes.REQUEST_MOTION_PERMISSION,
    payload: payload
  };
};

exports.createDefaultEventHandlers = function (circle) {
  return {
    type: EventHandlersActionTypes.CREATE_DEFAULT,
    payload: circle
  };
};
/**
 * Selectors
 */


exports.getEventHandlersMap = function (state) {
  return state.eventHandlers.events;
};

exports.getEventHandlers = function (state) {
  return Object.values(state.eventHandlers.events) || [];
};

exports.getEventHandlersFor = function (state, userId) {
  return Object.values(state.eventHandlers.events).filter(function (handler) {
    return handler.userId === userId;
  });
};

exports.getEventHandler = function (state, eventId) {
  return state.eventHandlers.events[eventId];
};

exports.getEventHandlersCrudErrors = function (state) {
  return state.eventHandlers.crudErrors;
};

exports.getMotionPermissionRequestedAt = function (state) {
  return state.eventHandlers.permissionRequestedAt;
};