import React, {useState} from "react";
import {connect} from "react-redux";
import MakeAsyncFunction from "react-redux-promise-listener";
import {promiseListener} from "../store/configureStore";
import CenterForm from "./CenterForm";
import PageHeader from "./PageHeader";
import {Center, User, getUsersList} from "../../../shared";
import {CentersActionTypes, selectUpsertCenterErrors} from "../store/Centers";
import SearchBar from "./SearchBar";
import {UserList} from "./UserList";

interface ProvidedProps {
  newCenter: boolean;
  center?: Center;
  history: any;
}

interface ConnectedProps extends ProvidedProps {
  upsertCenterErrors?: string;
  users: User[];
}

interface Props extends ConnectedProps {
  upsertCenter: (center: Partial<Center>) => Promise<any>;
}

function CenterView(props: Props) {
  const [editing, _setEditing] = useState(false);
  const [searchTerm, _setSearchTerm] = useState("");

  async function upsertCenter(center: Partial<Center>, isEditingExisting = false) {
    try {
      const result = await props.upsertCenter(center);
      if (isEditingExisting) {
        _setEditing(false);
      } else {
        props.history.replace("/centers/" + result._id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {props.newCenter && (
        <CenterForm
          onSubmit={upsertCenter}
          errors={props.upsertCenterErrors}
          onCancel={() => props.history.push("/centers/")}
        />
      )}
      {!props.newCenter &&
        props.center &&
        (editing ? (
          <CenterForm
            center={props.center}
            onSubmit={(center: Partial<Center>) => upsertCenter(center, true)}
            errors={props.upsertCenterErrors}
            onCancel={() => _setEditing(false)}
          />
        ) : (
          <>
            <PageHeader
              action={!editing ? "Edit" : undefined}
              actionOnClick={() => _setEditing(true)}
            >
              {props.center && props.center.name}
            </PageHeader>
            <div className="row">
              <div className="column">
                <span className="color-gray-5 d-block">Name</span>
                {props.center.name}
              </div>
              <div className="column">
                <span className="color-gray-5 d-block">Phone number</span>
                {props.center.phoneNumber}
              </div>
              <div className="column">
                <span className="color-gray-5 d-block">Email</span>
                {props.center.email}
              </div>
            </div>
            <PageHeader action="Add" actionTo={"/users/new?staff=1&center=" + props.center._id}>
              Staff Members
            </PageHeader>
            <SearchBar value={searchTerm} onChange={(e: any) => _setSearchTerm(e.target.value)} />
            <UserList showCircles={false} users={props.users} />
          </>
        ))}
    </>
  );
}

function CenterViewPromises(props: ConnectedProps) {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={CentersActionTypes.UPSERT_CENTER}
      resolve={CentersActionTypes.UPSERT_DONE}
      reject={CentersActionTypes.UPSERT_ERROR}
    >
      {(upsertCenterPromise: any) => <CenterView {...props} upsertCenter={upsertCenterPromise} />}
    </MakeAsyncFunction>
  );
}

export default connect((state: any, ownProps: ProvidedProps) => ({
  upsertCenterErrors: selectUpsertCenterErrors(state),
  users: ownProps.center
    ? getUsersList(state).filter(
        (u) => ownProps.center && u.centerId === ownProps.center._id && u.kind === "admin"
      )
    : [],
}))(CenterViewPromises);
