import React from "react";
import {connect} from "react-redux";
import AuthForm from "../components/AuthForm";
import Layout from "../components/Layout";
import {fetchMe, User, UserState, getCurrentUser} from "../../../shared";
import Logo from "../assets/HealthJay_192.png";
import classes from "./AuthPage.module.css";

interface Props {
  fetchMe: any;
  user?: User;
  history: any;
}

class AuthPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.fetchMe();
  }
  render() {
    if (this.props.user) {
      this.props.history.replace("/");
    }
    return (
      <Layout title="Login" navbar={undefined} className={classes.container}>
        <img src={Logo} className={classes.logo} />
        <span className={classes.title}>FlightDeck</span>
        <AuthForm />
      </Layout>
    );
  }
}

export default connect(
  (state: {user: UserState}) => ({
    user: getCurrentUser(state),
  }),
  {
    fetchMe,
  }
)(AuthPage as any);
