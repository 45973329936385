import React, {useEffect} from "react";
import {connect} from "react-redux";
import CenterView from "../components/CenterView";
import Layout from "../components/Layout";
import {RouteMatch} from "../components/Routes";
import NavBar from "../components/NavBar";
import {Routes} from "../components/Routes";
import {selectCenter} from "../store/Centers";
import {Center, getCurrentUser, User, loadUsers, getUsersList} from "../../../shared";
import {loadCenter} from "../store/Centers";

interface Props {
  center?: Center;
  match: RouteMatch;
  history: any;
  location: any;
  user?: User;
  users: User[];
  loadCenter: (centerId: string) => void;
  loadUsers: () => void;
}

function getTitle(props: Props) {
  if (props.center) {
    return props.center.name;
  } else if (props.match.params.id === "new") {
    return "Add Center";
  }
  return "Center";
}

function CenterPage(props: Props) {
  useEffect(() => {
    if (!props.center) {
      const id = props.match.params.id;
      if (id !== "new") {
        props.loadCenter(props.match.params.id);
      }
    }
    props.loadUsers();
  }, [props.match.params.id]);
  const title = getTitle(props);
  const newCenter = props.match.params.id === "new";
  return (
    <Layout
      title={title}
      navbar={
        <NavBar>
          {props.user && props.user.kind === "superAdmin" ? (
            <>
              <NavBar.DetailHeading to={Routes.Centers} separator={!newCenter}>
                <span className="color-black">{title}</span>
              </NavBar.DetailHeading>
              {!newCenter && <NavBar.Tabs tabs={[{name: "Info", selected: true}]} />}
            </>
          ) : (
            <>
              <NavBar.LogoHeading />
              <NavBar.Tabs
                tabs={[
                  {name: "Activity", to: Routes.Activity},
                  {name: "Members", to: Routes.Users},
                  {
                    name: "Center",
                    to: Routes.Center.replace(":id", props.match.params.id),
                    selected: true,
                  },
                ]}
              />
            </>
          )}
        </NavBar>
      }
    >
      <CenterView
        newCenter={props.match.params.id === "new"}
        center={props.center}
        history={props.history}
      />
    </Layout>
  );
}

export default connect(
  (state: any, ownProps: Props) => ({
    center: selectCenter(state, ownProps.match.params.id),
    user: getCurrentUser(state),
    users: getUsersList(state),
  }),
  {
    loadCenter,
    loadUsers,
  }
)(CenterPage as any);
