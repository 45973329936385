import React from "react";
import classNames from "classnames";
import classes from "./SearchBar.module.css";

export default function SearchBar(props: any) {
  return (
    <input
      type="text"
      value={props.value}
      onChange={props.onChange}
      placeholder="Search"
      className={classNames(classes.input, classes.important)}
    />
  );
}
