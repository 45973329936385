import React from "react";
import {Switch, Route} from "react-router-dom";
import {connect} from "react-redux";
import HomePage from "../pages/HomePage";
import AuthPage from "../pages/AuthPage";
import UsersPage from "../pages/UsersPage";
import UserPage from "../pages/UserPage";
import CirclePage from "../pages/CirclePage";
import CentersPage from "../pages/CentersPage";
import CenterPage from "../pages/CenterPage";
import LogoutPage from "../pages/LogoutPage";
import NotFoundPage from "../pages/NotFoundPage";
import {bootstrapApp} from "../store/App";
import {User, UserState, getCurrentUser} from "../../../shared";

export enum Routes {
  Home = "/",
  Login = "/auth",
  Logout = "/auth/logout",
  Users = "/users",
  User = "/users/:id",
  Circle = "/circles/:id",
  Centers = "/centers",
  Center = "/centers/:id",
  Activity = "/activity",
}

export interface RouteMatch {
  params: {
    id: string;
  };
  path: string;
}

interface Props {
  bootstrapApp: () => void;
  user?: User;
}

class App extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.bootstrapApp();
  }
  isSuperAdmin = () => this.props.user && this.props.user.kind === "superAdmin";
  isAdmin = () => this.props.user && this.props.user.kind === "admin";
  render() {
    return (
      <Switch>
        <Route path={Routes.Home} exact component={HomePage} />
        <Route path={Routes.Login} exact component={AuthPage} />
        <Route path={Routes.Logout} exact component={LogoutPage} />
        {(this.isAdmin() || this.isSuperAdmin()) && (
          <Route path={Routes.User} component={UserPage} />
        )}
        {(this.isAdmin() || this.isSuperAdmin()) && (
          <Route path={Routes.Center} exact component={CenterPage} />
        )}
        {this.isSuperAdmin() && <Route path={Routes.Centers} exact component={CentersPage} />}
        {this.isAdmin() && <Route path={Routes.Users} exact component={UsersPage} />}
        {this.isAdmin() && <Route path={Routes.Circle} component={CirclePage} />}
        {this.isAdmin() && <Route path={Routes.Users} exact component={UsersPage} />}
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

export default connect(
  (state: {user: UserState}) => ({
    user: getCurrentUser(state),
  }),
  {
    bootstrapApp,
  }
)(App as any);
