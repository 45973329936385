import {createSelector} from "reselect";
import {getCirclesState, getCirclesFor, getUsersList, User} from "../../../shared";

export const getCirclesByUser = createSelector(
  getUsersList,
  getCirclesState,
  (users, circlesState) =>
    users.reduce((acc: any, curr: User) => {
      acc[curr._id] = getCirclesFor({circles: circlesState} as any, curr._id);
      return acc;
    }, {})
);
