import React from "react";
import {connect} from "react-redux";
import Button from "./Button";
import {
  User,
  UserKind,
  getCirclesList,
  loadCircles,
  Circle,
  setInviteCode,
  stashCircleDetails,
  generateInviteCodeForCircle,
} from "../../../shared";
import {NavBar} from "./NavBar";
import formClasses from "./Form.module.css";

export interface FormState {
  fullName: string;
  phoneNumber?: string;
  email?: string;
  kind: UserKind;
  centerId?: string;
  _id?: string;
  password?: string;
  // this was for invalidating a cache of some kind? I don't remember now...
  submitted?: number;
  circleSourceTab: "create" | "join";
  circleName?: string;
  circleId?: string;
}

interface FormProps {
  user?: User;
  currentUser: User;
  centerId?: string;
  submitForm: (userValues: object) => Promise<any>;
  resetForm: () => void;
  isAdmin?: boolean;
  circles: Circle[];
  loadCircles: () => void;
  generateInviteCodeForCircle: (circleId: string) => Promise<string | undefined>;
  setInviteCode: (inviteCode: string) => void;
  stashCircleDetails: (circle: Partial<Circle>) => void;
}

const defaultFormState: FormState = {
  fullName: "",
  phoneNumber: "",
  email: "",
  kind: UserKind.user,
  centerId: undefined,
  _id: undefined,
  submitted: undefined,
  password: undefined,
  circleSourceTab: "create",
  circleName: "",
  circleId: undefined,
};

export class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);
    this.state = {
      ...defaultFormState,
      kind: props.isAdmin ? UserKind.admin : UserKind.user,
      ...this.props.user,
      centerId: this.props.centerId,
    };
  }

  componentDidMount() {
    if (this.newUser() && !this.props.isAdmin) {
      this.props.loadCircles();
    }
  }

  componentDidUpdate(prevProps: FormProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        ...defaultFormState,
        ...this.props.user,
        centerId: this.props.centerId,
      });
    }
  }

  newUser = () => !this.state._id;

  onSubmit = async (e: any) => {
    e.preventDefault();
    const newUser = this.newUser();
    if (newUser) {
      switch (this.state.circleSourceTab) {
        case "join": {
          if (!this.state.circleId) {
            console.error("Unable to join circle without selecting a circle");
            break;
          }

          // Warning: global state (be sure to reset after use)
          const inviteCode = await this.props.generateInviteCodeForCircle(this.state.circleId);
          if (!inviteCode) {
            console.error("Unable to join circle, cannot get invite code");
            return;
          }
          this.props.setInviteCode(inviteCode);
          break;
        }

        case "create": {
          if (!this.state.circleName) {
            console.error("Unable to create circle without name");
            break;
          }
          // Warning: global state (be sure to reset after use)
          this.props.stashCircleDetails({
            name: this.state.circleName,
          });
          break;
        }
      }
    }
    if (!this.state.centerId && !this.state._id) {
      console.error("Unable to save user without center");
      return;
    }
    await this.props.submitForm({
      fullName: this.state.fullName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      kind: this.state.kind,
      centerId: this.state.centerId,
      _id: this.state._id,
      password: this.state.password,
      sendEmail: !(this.props.user && this.props.user._id),
    });

    // Resets stashed circle / invite-code
    this.props.stashCircleDetails({});
  };

  render() {
    const newUser = this.newUser();
    return (
      <>
        <form onSubmit={this.onSubmit} className={formClasses.form}>
          <label>
            Name
            <input
              type="text"
              name="fullName"
              value={this.state.fullName}
              onChange={(e) => this.setState({fullName: e.target.value})}
              required
            />
          </label>
          <label>
            Email
            <input
              type="email"
              name="email"
              value={this.state.email}
              onChange={(e) => this.setState({email: e.target.value})}
            />
          </label>
          <label>
            Phone number
            <input
              type="tel"
              name="phoneNumber"
              value={this.state.phoneNumber}
              onChange={(e) => this.setState({phoneNumber: e.target.value})}
              required
            />
          </label>
          {!newUser && (
            <label>
              Kind
              <select
                name="kind"
                value={this.state.kind}
                onChange={(e) => this.setState({kind: e.target.value as UserKind})}
                disabled={this.props.user && this.props.currentUser._id === this.props.user._id}
              >
                {Object.keys(UserKind).map((kind) => (
                  <option
                    value={kind}
                    key={kind}
                    disabled={kind === "superAdmin" && this.props.currentUser.kind !== "superAdmin"}
                  >
                    {kind}
                  </option>
                ))}
              </select>
            </label>
          )}
          {newUser && (
            <label>
              Password
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
                required
              />
            </label>
          )}
          {newUser && !this.props.isAdmin && (
            <>
              <NavBar border={false} className={formClasses.tabbedSection}>
                <NavBar.Tabs
                  tabs={[
                    {
                      name: "Create New Circle",
                      selected: this.state.circleSourceTab === "create",
                      onClick: () => this.setState({circleSourceTab: "create"}),
                      className: formClasses.firstTab,
                    },
                    {
                      name: "Join Existing Circle",
                      selected: this.state.circleSourceTab === "join",
                      onClick: () => this.setState({circleSourceTab: "join"}),
                    },
                  ]}
                />
              </NavBar>
              {this.state.circleSourceTab === "create" && (
                <label>
                  Circle name
                  <input
                    type="text"
                    name="circleName"
                    value={this.state.circleName}
                    onChange={(e) => this.setState({circleName: e.target.value})}
                    required
                  />
                </label>
              )}
              {this.state.circleSourceTab === "join" && (
                <label>
                  Choose circle
                  <select
                    name="circleId"
                    value={this.state.circleId}
                    onChange={(e) => this.setState({circleId: e.target.value})}
                  >
                    <option value={undefined} key={undefined}>
                      -- Select one --
                    </option>
                    {this.props.circles.map((circle) => (
                      <option value={circle._id} key={circle._id}>
                        {circle.name}
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </>
          )}
          <Button type="submit">Save</Button>
          <Button onClick={this.props.resetForm} variant="secondary">
            Cancel
          </Button>
        </form>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    circles: getCirclesList(state),
  }),
  {
    loadCircles,
    generateInviteCodeForCircle,
    setInviteCode,
    stashCircleDetails,
  }
)(Form);
