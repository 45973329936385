import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import querystring from "querystring";
import {Link} from "react-router-dom";
import {User, UserState, getCurrentUser} from "../../../shared";
import Logo from "../assets/HealthJay_192.png";
import ClearIcon from "./ClearIcon";
import classes from "./NavBar.module.css";

interface Props {
  user?: User;
  children: any;
  border?: boolean;
  className?: string;
}

export function NavBar(props: Props) {
  return (
    <nav
      className={classNames(
        classes.container,
        "color-gray-1",
        props.border === false && classes.noBorder,
        props.className
      )}
    >
      <div className={classNames(classes.nav, "color-black", "fs-18")}>{props.children}</div>
      {props.user && (
        <>
          <span className={classNames(classes.greeting, "color-gray-2")}>
            <span className="color-black fs-18">Hi {props.user.fullName}</span>
          </span>
          <Link to="/auth/logout/" className={classNames("fs-18", "color-red-5", classes.logout)}>
            Log out
          </Link>
        </>
      )}
    </nav>
  );
}

const NavBarConnected = connect((state: {user: UserState}) => ({
  user: getCurrentUser(state),
}))(NavBar as any);

export default NavBarConnected;

interface TabsProps {
  tabs: {
    name: string;
    to?: string;
    selected?: boolean;
    onClick?: () => void;
    className?: string;
  }[];
}

NavBar.Tabs = NavBarConnected.Tabs = function Tabs(props: TabsProps) {
  const buttonClick = (e: any, onClick?: () => void) => {
    e.preventDefault();
    onClick && onClick();
  };
  return (
    <>
      {props.tabs.map((tab) => (
        <span
          className={classNames(
            "color-blue-5",
            classes.navChild,
            tab.selected && classes.navChildSelected,
            tab.className
          )}
          key={tab.name}
        >
          {tab.to && (
            <Link to={tab.to} className="color-black">
              {tab.name}
            </Link>
          )}
          {!tab.to && tab.onClick && (
            <button
              className={classNames("color-black", classes.buttonTab)}
              onClick={(e) => buttonClick(e, tab.onClick)}
            >
              {tab.name}
            </button>
          )}
          {!tab.to && !tab.onClick && <span className={classNames("color-black")}>{tab.name}</span>}
        </span>
      ))}
    </>
  );
};

NavBar.LogoHeading = NavBarConnected.LogoHeading = function LogoHeading(props: any) {
  return (
    <Link to="/">
      <img className={classes.img} src={Logo} />
    </Link>
  );
};

interface DetailHeadingInterface {
  to: string;
  children: any;
  separator?: boolean;
  className?: string;
}

NavBar.DetailHeading = NavBarConnected.DetailHeading = function DetailHeading(
  props: DetailHeadingInterface
) {
  const {to, children, ...rest} = props;
  return (
    <>
      <Link to={to}>
        <ClearIcon
          {...rest}
          className={classNames("color-gray-3", classes.closeIcon, props.className)}
        />
      </Link>
      <span
        className={classNames(
          classes.navChild,
          props.separator !== false && classes.navChildBorderRight,
          "color-gray-3"
        )}
      >
        {children}
      </span>
    </>
  );
};
