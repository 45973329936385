import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PageHeader from "./PageHeader";

import {UsersManagerState, CirclesState, getUsersList, User, Circle} from "../../../shared";

import {getCirclesByUser} from "../store/selectors";
import classes from "./UserList.module.css";

export const LinkCell = ({to, children}: any) => (
  <td className={classes.linkcelltd}>
    <Link to={to} className={classes.linkcell}>
      {children}
    </Link>
  </td>
);

const getPrettyUserType = (user: User) => {
  if (user.kind === "superAdmin") {
    return "HealthJay Staff";
  }
  if (user.kind === "admin") {
    return "Admin";
  }
  if (user.kind === "staff") {
    return "Staff";
  }
  if (user.kind === "user") {
    // @todo - if non-resident, return 'Family'
    return "Senior";
  }
  return "Member";
};

interface ClinicUserTableRowProps {
  user: User;
  circles: Circle[];
  showCircles: boolean;
}

const ClinicUserTableRow: React.FC<ClinicUserTableRowProps> = (props: ClinicUserTableRowProps) => {
  const {user, circles, showCircles} = props;
  return (
    <tr>
      <td className="fs-18">
        <Link to={"/users/" + user._id}>{user.fullName}</Link>
      </td>
      <td>
        <span className="o-50">{getPrettyUserType(user)}</span>
      </td>
      <td>
        <span className="o-50">{user.email}</span>
      </td>
      {showCircles && (
        <td>
          {circles.map((circle, index) => (
            <Link to="/">
              {circle.name}
              {index + 1 < circles.length ? "," : ""}
            </Link>
          ))}
        </td>
      )}
    </tr>
  );
};

interface UserListProps {
  users: User[];
  circlesByUser?: {
    [id: string]: Circle[];
  };
  showCircles?: boolean;
}

export function UserList(props: UserListProps) {
  const {users, circlesByUser = {}, showCircles = true} = props;
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Email</th>
          {showCircles && <th>Circle</th>}
        </tr>
      </thead>
      <tbody>
        {users.map((user: any) => (
          <ClinicUserTableRow
            key={user._id}
            user={user}
            showCircles={showCircles}
            circles={circlesByUser[user._id]}
          />
        ))}
      </tbody>
    </table>
  );
}

function UserListWithHeader(props: UserListProps) {
  return (
    <>
      <PageHeader action="Add new member" actionTo="/users/new">
        Members
      </PageHeader>
      <UserList {...props} />
    </>
  );
}

export default connect((state: {users: UsersManagerState; circles: CirclesState}) => ({
  users: getUsersList(state).filter((u) => u.kind === "user"),
  circlesByUser: getCirclesByUser(state),
}))(UserListWithHeader as any);
