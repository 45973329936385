"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var interfaces_1 = require("./interfaces");

var Utilities_1 = require("./Utilities");

var getShortDayString = function getShortDayString(message) {
  return Utilities_1.getDayString(message.created).toUpperCase();
};

var messageIsEvent = function messageIsEvent(message) {
  return message.kind === interfaces_1.MessageKind.event;
};

var messageIsAfterTimeGap = Utilities_1.isAfterTimeGap;

var messageIsAction = function messageIsAction(message) {
  return message.kind === interfaces_1.MessageKind.actionButton;
};

var isMultiple = function isMultiple(message, index, messages) {
  if (messageIsAction(message)) {
    return false;
  }

  var userId = message.userId;
  var previousIsUser = index > 0 && messages[index - 1].userId === userId && !messageIsAction(messages[index - 1]); // Don't count next message as part of group if after a gap

  var nextIsUser = index + 1 < messages.length && messages[index + 1].userId === userId && !messageIsAction(messages[index + 1]) && !messageIsAfterTimeGap(messages[index + 1], index + 1, messages); // If current message is after a gap, can start a new group, but won't be a part of the previous group

  if (messageIsAfterTimeGap(message, index, messages)) {
    if (nextIsUser) {
      return "many_first";
    }

    return false;
  }

  if (previousIsUser && nextIsUser) {
    return "many_middle";
  }

  if (previousIsUser && !nextIsUser) {
    return "many_last";
  }

  if (!previousIsUser && nextIsUser) {
    return "many_first";
  }

  return false;
};

exports.chatUtils = {
  getShortDayString: getShortDayString,
  messageIsEvent: messageIsEvent,
  messageIsAfterTimeGap: messageIsAfterTimeGap,
  messageIsAction: messageIsAction,
  isMultiple: isMultiple
};