import React from "react";
import {connect} from "react-redux";
import UserList from "../components/UserList";
import NavBar from "../components/NavBar";
import {Routes} from "../components/Routes";
import Layout from "../components/Layout";

import {loadUsers, loadCircles, getCurrentUser, User} from "../../../shared";

interface Props {
  loadUsers: any;
  loadCircles: any;
  match: any;
  location: any;
  user?: User;
}

class UsersPage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.loadUsers();
    this.props.loadCircles();
  }
  render() {
    return (
      <Layout
        title="Members"
        navbar={
          <NavBar>
            <NavBar.LogoHeading />
            {this.props.user && this.props.user.kind !== "superAdmin" && (
              <NavBar.Tabs
                tabs={[
                  {name: "Activity", to: Routes.Activity},
                  {name: "Members", to: Routes.Users, selected: true},
                  {
                    name: "Center",
                    to: Routes.Center.replace(
                      ":id",
                      this.props.user && (this.props.user.centerId as string)
                    ),
                  },
                ]}
              />
            )}
          </NavBar>
        }
      >
        <UserList />
      </Layout>
    );
  }
}

export default connect(
  (state: any) => ({
    user: getCurrentUser(state),
  }),
  {
    loadUsers,
    loadCircles,
  }
)(UsersPage as any);
