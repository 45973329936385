import {takeEvery, call, put} from "redux-saga/effects";
import {UserActionTypes, saveToken, saveUser} from "../../../shared";
function* saveTokenAction(action: any) {
  if (!action.payload) {
    return;
  }
  yield call([localStorage, "setItem"], "jwtToken", action.payload);
  return;
}

function* saveUserAction(action: any) {
  if (!action.payload) {
    return;
  }
  const user = {
    _id: action.payload._id,
    email: action.payload.email,
    fullName: action.payload.fullName,
    kind: action.payload.kind,
    circles: action.payload.circles,
    centerId: action.payload.centerId,
  };
  yield call([localStorage, "setItem"], "user", JSON.stringify(user));
  return;
}

export function* loginSaga() {
  yield takeEvery(UserActionTypes.SAVE_TOKEN, saveTokenAction);
  yield takeEvery(UserActionTypes.SAVE_USER, saveUserAction);
}

function* removeTokenAction() {
  yield call([localStorage, "removeItem"], "jwtToken");
  return;
}

export function* logoutSaga() {
  yield takeEvery(UserActionTypes.LOG_OUT, removeTokenAction);
}

export function* bootstrapAuthAction() {
  // @todo - invalidate credentials if token is expired
  try {
    const token = yield call([localStorage, "getItem"], "jwtToken");
    const user = JSON.parse(yield call([localStorage, "getItem"], "user"));
    if (token && user) {
      yield put(saveToken(token));
      yield put(saveUser(user));
    }
  } catch (e) {
    // pass
  }
}
