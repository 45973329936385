import React from "react";
import Layout from "../components/Layout";
import NavBar from "../components/NavBar";

const NotFoundPage: React.FC = (props: any) => (
  <Layout
    navbar={
      <NavBar>
        <NavBar.LogoHeading />
      </NavBar>
    }
  >
    <h1>Page not found</h1>
  </Layout>
);

export default NotFoundPage;
