import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";
import querystring from "querystring";
import UserView from "../components/UserView";
import Layout from "../components/Layout";
import NavBar from "../components/NavBar";
import {RouteMatch, Routes} from "../components/Routes";
import {
  loadUser,
  getUsersList,
  getUserById,
  User,
  UsersManagerState,
  Circle,
  CirclesState,
  loadCircles,
  getCirclesFor,
  getCirclesList,
  getCurrentUser,
} from "../../../shared";

interface Props {
  currentUser?: User;
  user?: User;
  users?: User[];
  loadUser: any;
  circlesUser?: Circle[];
  circlesAll?: Circle[];
  loadCircles: any;
  match: RouteMatch;
  history: any;
  location: any;
}

function getTitle(isAdmin = false, newUser = false, user?: User) {
  if (user) {
    return user.fullName;
  } else if (newUser) {
    return `Add ${isAdmin ? "Staff" : "Member"}`;
  }
  return "Member";
}

function UserPage(props: Props) {
  useEffect(() => {
    if (!props.user) {
      const id = props.match.params.id;
      if (id !== "new") {
        props.loadUser(id);
      }
    }
    if (!props.circlesAll || props.circlesAll.length === 0) {
      props.loadCircles();
    }
  }, [props.match.params.id]);

  const {staff: isAdmin, center: centerId, tab: selectedTab = "info"} = querystring.parse(
    props.location.search.slice(1)
  );
  const backToCenter = props.user ? props.user.centerId : centerId;
  const backDestination =
    (props.currentUser && props.currentUser.kind === "superAdmin") ||
    (props.user && props.user.kind === "admin") ||
    isAdmin
      ? Routes.Center.replace(":id", backToCenter as string)
      : Routes.Users;
  const newUser = props.match.params.id === "new";
  const title = getTitle(!!isAdmin, newUser, props.user);
  const navBarTabs = [
    {
      name: "Info",
      to: Routes.User.replace(":id", props.match.params.id) + "?tab=" + "info",
      selected: selectedTab === "info",
    },
  ];
  if (!newUser && props.user && props.user.kind !== "admin") {
    navBarTabs.unshift(
      {
        name: "Activity",
        to: Routes.User.replace(":id", props.match.params.id) + "?tab=" + "activity",
        selected: selectedTab === "activity",
      },
      {
        name: "Chat",
        to: Routes.User.replace(":id", props.match.params.id) + "?tab=" + "chat",
        selected: selectedTab === "chat",
      }
    );
    // if props.user && props.user.kind !== 'user' && selectedTab !== 'info' >>> error
  }
  const cancelFunction = useCallback(() => {
    if (newUser) {
      props.history.push(backDestination);
    }
  }, [newUser, backDestination]);

  return (
    <Layout
      title={title}
      navbar={
        <NavBar>
          <NavBar.DetailHeading to={backDestination} separator={!newUser}>
            <span className="color-black">{title}</span>
          </NavBar.DetailHeading>
          {!newUser && <NavBar.Tabs tabs={navBarTabs} />}
        </NavBar>
      }
    >
      {selectedTab === "info" && (
        <UserView
          newUser={props.match.params.id === "new"}
          newAdmin={!!isAdmin}
          centerId={centerId as string}
          user={props.user}
          circles={props.circlesUser}
          history={props.history}
          cancelEditing={cancelFunction}
        />
      )}
      {selectedTab === "chat" && <p>to do</p>}
      {selectedTab === "activity" && <p>to do</p>}
    </Layout>
  );
}

export default connect(
  (state: any, ownProps: any) => ({
    currentUser: getCurrentUser(state),
    user: getUserById(state, ownProps.match.params.id),
    users: getUsersList(state),

    circlesUser: getCirclesFor(state, ownProps.match.params.id),
    circlesAll: getCirclesList(state),
  }),
  {
    loadUser,
    loadCircles,
  }
)(UserPage as any);
