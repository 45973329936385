/**
 * Types
 */

export enum AppActions {
  BOOTSTRAP_APP = "@@app/BOOTSTRAP_APP",
}

/**
 * Actions
 */

export const bootstrapApp = () => ({
  type: AppActions.BOOTSTRAP_APP,
});
