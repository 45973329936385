import React from "react";
import {connect} from "react-redux";

import classNames from "classnames";
import MakeAsyncFunction from "react-redux-promise-listener";
import Form, {FormState} from "./CircleForm";
import {LinkCell} from "./UserList";
import MessagesView from "./MessagesView";
import {promiseListener} from "../store/configureStore";
import {
  Circle,
  CirclesActionTypes,
  CirclesState,
  getCirclesUpsertErrors,
  clearCirclesUpsertErrors,
  UsersMap,
  UsersManagerState,
  loadUsers,
  getUsersMap,
  getUserName,
  deleteCircle,
} from "../../../shared";

/**
 * Renders static circle data
 */

interface CircleDataProps {
  circle: Circle;
  usersMap: UsersMap;
}

const CircleData: React.FC<CircleDataProps> = ({circle, usersMap}: CircleDataProps) => (
  <table>
    <thead>
      <tr>
        <td>Name</td>
        <td style={{width: 200}} />
      </tr>
    </thead>
    <tbody>
      {circle.members.map((id) => (
        <tr key={id}>
          <LinkCell to={"/users/" + id}>{getUserName(usersMap[id])}</LinkCell>
          <LinkCell to={"/users/" + id}>{circle.admins.includes(id) ? "Admin" : ""}</LinkCell>
        </tr>
      ))}
    </tbody>
  </table>
);

/**
 * Parent component handles view state
 */

interface BaseCircleViewProps {
  newCircle: boolean;
  circle?: Circle;
  history: any;
  errors?: string;
  clearCirclesUpsertErrors: any;
  usersMap: UsersMap;
  loadUsers: any;
  lastViewedUser?: string;
  deleteCircle: any;
}

interface CircleViewProps extends BaseCircleViewProps {
  createCircle: any;
  updateCircle: any;
}

interface CircleViewState {
  editing: boolean;
}

class CircleView extends React.Component<CircleViewProps, CircleViewState> {
  constructor(props: CircleViewProps) {
    super(props);
    this.state = {
      editing: props.newCircle,
    };
    if (!Object.keys(props.usersMap).length) {
      props.loadUsers();
    }
  }

  handleSubmit = (circleValues: FormState) => {
    if (this.props.newCircle) {
      this.props
        .createCircle(circleValues)
        .then((res: any) => {
          if (this.props.lastViewedUser) {
            this.props.history.replace("/users/" + this.props.lastViewedUser);
          } else {
            this.props.history.replace("/circles/" + res._id);
          }
          this.resetForm();
        })
        .catch(() => {
          // pass
        });
    } else {
      this.props
        .updateCircle(circleValues)
        .then(() => {
          this.resetForm();
        })
        .catch(() => {
          // pass
        });
    }
  };

  resetForm = () => {
    this.props.clearCirclesUpsertErrors();
    if (this.props.newCircle) {
      this.props.history.push("/users/" + (this.props.lastViewedUser || ""));
      return;
    }
    this.setState({editing: false});
  };

  deleteCircle = () => {
    if (!(this.props.circle && this.props.circle._id)) {
      return;
    }

    const response = window.confirm("Are you sure? This action cannot be undone.");
    if (!response) {
      return;
    }
    this.props.deleteCircle(this.props.circle._id);
    this.props.history.replace("/users/" + (this.props.lastViewedUser || ""));
  };

  render() {
    const {newCircle, circle, errors, usersMap, lastViewedUser} = this.props;
    const {editing} = this.state;
    let title = "";

    if (newCircle) {
      title = "Create New Circle";
    } else if (circle) {
      title = `${circle.name}`;
    }

    return (
      <>
        <div className="clearfix">
          <h1 className="float-left">{title}</h1>
          {!editing && (
            <button
              className={classNames("button", "button-outline", "float-right")}
              onClick={() => this.setState({editing: true})}
            >
              Edit Circle
            </button>
          )}
          {editing && !newCircle && (
            <button
              className={classNames("button", "button-black", "float-right")}
              onClick={this.deleteCircle}
            >
              Delete Circle
            </button>
          )}
        </div>
        {editing && errors && <p>Error! {errors}</p>}
        {!editing && circle && (
          <>
            <h2>Members</h2>
            <CircleData circle={circle} usersMap={usersMap} />
          </>
        )}
        {editing && (
          <Form
            circle={circle}
            lastViewedUser={lastViewedUser}
            submitForm={this.handleSubmit}
            resetForm={this.resetForm}
          />
        )}
        {!newCircle && circle && (
          <>
            <h2>Chat messages</h2>
            <MessagesView circle={circle} />
          </>
        )}
      </>
    );
  }
}

/**
 * Dispatches create/update actions and listen for completion
 */

const CircleViewPromises: React.FC<BaseCircleViewProps> = (props: BaseCircleViewProps) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={CirclesActionTypes.CREATE_CIRCLE}
    resolve={CirclesActionTypes.UPSERT_DONE}
    reject={CirclesActionTypes.UPSERT_ERROR}
  >
    {(createCirclePromise: any) => (
      <MakeAsyncFunction
        listener={promiseListener}
        start={CirclesActionTypes.UPDATE_CIRCLE}
        resolve={CirclesActionTypes.UPSERT_DONE}
        reject={CirclesActionTypes.UPSERT_ERROR}
      >
        {(updateCirclePromise: any) => (
          <CircleView
            createCircle={createCirclePromise}
            updateCircle={updateCirclePromise}
            {...props}
          />
        )}
      </MakeAsyncFunction>
    )}
  </MakeAsyncFunction>
);

export default connect(
  (state: {circles: CirclesState; users: UsersManagerState}) => ({
    errors: getCirclesUpsertErrors(state),
    usersMap: getUsersMap(state),
  }),
  {
    clearCirclesUpsertErrors,
    loadUsers,
    deleteCircle,
  }
)(CircleViewPromises);
