import React from "react";
import {connect} from "react-redux";
import Layout from "../components/Layout";
import NavBar from "../components/NavBar";
import {fetchMe, User, UserState, getCurrentUser, getUserLoading} from "../../../shared";

interface Props {
  fetchMe: any;
  user?: User;
  history: any;
  userLoading: boolean;
  match: any;
  location: any;
}

class HomePage extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.fetchMe();
  }

  render() {
    if (!this.props.user && !this.props.userLoading) {
      this.props.history.replace("/auth");
    }
    if (this.props.user && this.props.user.kind === "superAdmin") {
      this.props.history.replace("/centers/");
    }
    if (this.props.user && this.props.user.kind === "admin") {
      this.props.history.replace("/users/");
    }
    return (
      <Layout
        navbar={
          <NavBar>
            <NavBar.LogoHeading />
          </NavBar>
        }
        children={null}
      />
    );
  }
}

export default connect(
  (state: {user: UserState}) => ({
    user: getCurrentUser(state),
    userLoading: getUserLoading(state),
  }),
  {
    fetchMe,
  }
)(HomePage as any);
