"use strict";

var _objectSpread = require("/opt/build/repo/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var UserStore_1 = require("./UserStore");

var EventsActionTypes;

(function (EventsActionTypes) {
  EventsActionTypes["FETCH_ACTIVITIES"] = "@@events/FETCH_ACTIVITIES";
  EventsActionTypes["ACTIVITIES_LOADED"] = "@@events/ACTIVITIES_LOADED";
  EventsActionTypes["LOADING_ERROR"] = "@@events/LOADING_ERROR";
})(EventsActionTypes = exports.EventsActionTypes || (exports.EventsActionTypes = {}));
/**
 * Reducer
 */


exports.initialEventsState = {
  activityEvents: {},
  loading: false,
  errors: undefined
};

function eventsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : exports.initialEventsState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case EventsActionTypes.FETCH_ACTIVITIES:
      {
        return _objectSpread({}, state, {
          loading: true,
          errors: undefined
        });
      }

    case EventsActionTypes.ACTIVITIES_LOADED:
      {
        return _objectSpread({}, state, {
          loading: false,
          activityEvents: action.payload.activityEvents
        });
      }

    case EventsActionTypes.LOADING_ERROR:
      {
        return _objectSpread({}, state, {
          loading: false,
          errors: action.payload
        });
      }

    case UserStore_1.UserActionTypes.LOG_OUT:
      {
        return exports.initialEventsState;
      }

    default:
      return state;
  }
}

exports.eventsReducer = eventsReducer;
/**
 * Actions
 */

exports.loadActivityEvents = function () {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return {
    type: EventsActionTypes.FETCH_ACTIVITIES,
    payload: {
      page: page
    }
  };
};

exports.activityEventsLoaded = function (activityEventsMap) {
  return {
    type: EventsActionTypes.ACTIVITIES_LOADED,
    payload: {
      activityEvents: activityEventsMap
    }
  };
};
/**
 * Selectors
 */


exports.getActivityEventsMap = function (state) {
  return state.events.activityEvents;
};

exports.getActivityEvents = function (state) {
  return Object.values(state.events.activityEvents) || [];
};

exports.getActivityEvent = function (state, activityEventId) {
  return state.events.activityEvents[activityEventId];
};