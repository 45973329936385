import React, {useState} from "react";
import {connect} from "react-redux";
import {get} from "lodash";
import Button from "./Button";
import {Center} from "../../../shared";
import formClasses from "./Form.module.css";

interface Props {
  onSubmit: (center: Partial<Center>) => Promise<any>;
  onCancel: () => void;
  errors?: string;
  center?: Center;
}

function _CenterForm(props: any) {
  const [form, _setForm] = useState({
    name: get(props, "center.name", ""),
    address: get(props, "center.address", ""),
    phoneNumber: get(props, "center.phoneNumber", ""),
    email: get(props, "center.email", ""),
  });

  function setFormValue(partial: any) {
    _setForm((prev: any) => ({...prev, ...partial}));
  }

  async function onSubmit(e: any) {
    e.preventDefault();
    await props.onSubmit({
      _id: get(props, "center._id", undefined),
      ...form,
    });
  }

  return (
    <form onSubmit={onSubmit} className={formClasses.form}>
      <label>
        Center name
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={form.name}
          onChange={(e) => setFormValue({name: e.target.value})}
          required
        />
      </label>

      <label>
        Address
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={form.address}
          onChange={(e) => setFormValue({address: e.target.value})}
          required
        />
      </label>

      <label>
        Phone number
        <input
          type="text"
          name="phoneNumber"
          placeholder="000-000-0000"
          value={form.phoneNumber}
          onChange={(e) => setFormValue({phoneNumber: e.target.value})}
          required
        />
      </label>

      <label>
        Email
        <input
          type="text"
          name="email"
          placeholder="Email"
          value={form.email}
          onChange={(e) => setFormValue({email: e.target.value})}
          required
        />
      </label>

      <Button type="submit">{props.center ? "Save" : "Create"}</Button>
      <Button variant="secondary" type="button" onClick={props.onCancel}>
        Cancel
      </Button>
    </form>
  );
}

const CenterForm = connect()(_CenterForm);

export default CenterForm;
