import React, {useEffect} from "react";
import {connect} from "react-redux";
import Layout from "../components/Layout";
import CenterList from "../components/CenterList";
import NavBar from "../components/NavBar";
import {Routes} from "../components/Routes";
import {loadCenters} from "../store/Centers";
import {loadUsers, getCurrentUser} from "../../../shared";

function CentersPage(props: any) {
  useEffect(() => {
    props.loadCenters();
    props.loadUsers();
  }, []);

  return (
    <Layout
      title="Centers"
      navbar={
        <NavBar>
          <NavBar.LogoHeading />
          {props.user && props.user.kind === "superAdmin" && (
            <NavBar.Tabs tabs={[{name: "Centers", to: Routes.Centers, selected: true}]} />
          )}
        </NavBar>
      }
    >
      <CenterList />
    </Layout>
  );
}

export default connect(
  (state: any) => ({
    user: getCurrentUser(state),
  }),
  {
    loadCenters,
    loadUsers,
  }
)(CentersPage);
