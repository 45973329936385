import React from "react";
import {connect} from "react-redux";
import {
  Circle,
  User,
  UsersMap,
  UsersManagerState,
  getUsersList,
  getUsersMap,
  loadUsers,
  getUserName,
} from "../../../shared";

/**
 * User select menu
 */
interface UserSelectProps {
  users: User[];
  value: string;
  onChange: any;
  className?: string;
}
const UserSelect: React.FC<UserSelectProps> = ({
  users,
  value,
  onChange,
  ...rest
}: UserSelectProps) => (
  <select onChange={(e: any) => onChange(e.target.value)} value={value} {...rest}>
    <option value="">Select a user</option>
    {users.map((user: User) => (
      <option value={user._id} key={user._id}>
        {user.fullName}
      </option>
    ))}
  </select>
);

/**
 * Admin toggle
 */

interface AdminToggleProps {
  checked: boolean;
  onChange: any;
  className?: string;
}
const AdminToggle: React.FC<AdminToggleProps> = ({
  checked,
  onChange,
  ...rest
}: AdminToggleProps) => (
  <input
    type="checkbox"
    checked={checked}
    onChange={(e: any) => onChange(e.target.checked)}
    {...rest}
  />
);

/**
 * Parent component
 */

export interface FormState {
  name: string;
  members: string[];
  admins: string[];
  _id?: string;
  submitted?: string;
  newUserSelect: string;
}

interface FormProps {
  circle?: Circle;
  lastViewedUser?: string;
  // suggestedUser: User;
  submitForm: (userValues: object) => void;
  resetForm: () => void;
  users: User[];
  usersMap: UsersMap;
  loadUsers: any;
}

const defaultFormState: FormState = {
  name: "",
  members: [],
  admins: [],
  _id: undefined,
  submitted: undefined,
  newUserSelect: "",
};

export class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);
    const state = {
      ...defaultFormState,
      ...this.props.circle,
    };
    if (!props.users || props.users.length === 0) {
      props.loadUsers();
    }
    if (props.lastViewedUser) {
      if (!state.members.includes(props.lastViewedUser)) {
        state.members = state.members.concat(props.lastViewedUser);
      }
    }
    this.state = state;
  }

  componentDidUpdate(prevProps: FormProps) {
    if (prevProps.circle !== this.props.circle) {
      this.setState({
        ...defaultFormState,
        ...this.props.circle,
      });
    }
  }

  onSubmit = async (e: any) => {
    e.preventDefault();
    this.props.submitForm(this.state);
  };

  newUserSelectChange = (id: string) => {
    // @todo - clear newUserSelect error
    this.setState({newUserSelect: id});
  };

  addMember = () => {
    if (!this.state.newUserSelect) {
      // @todo - set newUserSelect error
      return;
    }
    this.setState((prevState: FormState) => ({
      members: prevState.members.concat(prevState.newUserSelect),
      newUserSelect: "",
    }));
  };

  toggleAdmin = (id: string) => {
    this.setState((prevState: FormState) => {
      if (prevState.admins.includes(id)) {
        return {admins: prevState.admins.filter((x) => x !== id)};
      }
      return {admins: prevState.admins.concat(id)};
    });
  };

  removeMember = (id: string) => {
    this.setState((prevState: FormState) => ({
      members: prevState.members.filter((x) => x !== id),
      admins: prevState.admins.filter((x) => x !== id),
    }));
  };

  render() {
    // const newCircle = !(this.props.circle && this.props.circle._id);
    return (
      <>
        <form onSubmit={this.onSubmit}>
          <label>
            Circle Name:
            <input
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => this.setState({name: e.target.value})}
              required
            />
          </label>

          <label>Members:</label>
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td style={{width: 200}}>Admin</td>
                <td style={{width: 150}} />
              </tr>
            </thead>
            <tbody>
              {this.state.members.map((id) => (
                <tr key={id}>
                  <td>{getUserName(this.props.usersMap[id])}</td>
                  <td>
                    <AdminToggle
                      checked={this.state.admins.includes(id)}
                      onChange={() => this.toggleAdmin(id)}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="float-right button-outline"
                      onClick={() => this.removeMember(id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3}>
                  <div className="container" style={{padding: "0 1rem"}}>
                    <div className="row">
                      {/* @todo - display newUserSelect error */}
                      <UserSelect
                        className="column"
                        users={this.props.users.filter(
                          (user) => !this.state.members.includes(user._id)
                        )}
                        value={this.state.newUserSelect}
                        onChange={this.newUserSelectChange}
                      />
                      <button
                        className="column column-offset-10 column-20"
                        type="button"
                        onClick={this.addMember}
                      >
                        Add Circle Member
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>

          <button onClick={this.props.resetForm} type="button" className="button-outline">
            Cancel
          </button>
          {` `}
          <button type="submit">Save</button>
        </form>
      </>
    );
  }
}

export default connect(
  (state: {users: UsersManagerState}) => ({
    users: getUsersList(state),
    usersMap: getUsersMap(state),
  }),
  {
    loadUsers,
  }
)(Form);
