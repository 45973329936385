import React from "react";
import {connect} from "react-redux";
import Layout from "../components/Layout";
import {logout} from "../../../shared";

interface Props {
  logout: any;
  history: any;
}

class Logout extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.props.logout();
    this.props.history.replace("/");
  }
  render() {
    return (
      <Layout navbar={undefined}>
        <div />
      </Layout>
    );
  }
}

export default connect(
  null,
  {
    logout,
  }
)(Logout as any);
