import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import EventIcon from "./Notifications";
import {
  Message,
  User,
  Circle,
  getUsersMap,
  UsersMap,
  loadUsers,
  MessageKind,
  getMessagesFor,
  getCurrentUser,
  loadMessages,
  MessagesActionTypes,
  adminCircleListen,
  adminCircleUnlisten,
  getUserJWT,
  getSocketSubscriptions,
  MessageMultiple,
  chatUtils,
} from "../../../shared";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import CloseIcon from "@material-ui/icons/Close";
import MakeAsyncFunction from "react-redux-promise-listener";
import {promiseListener} from "../store/configureStore";
import classes from "./MessagesView.module.css";
import sendIcon from "../assets/send-2x.png";
import ReactMediaRecorder from "react-media-recorder";
import axios from "axios";

const {getShortDayString, messageIsEvent, messageIsAfterTimeGap, isMultiple} = chatUtils;

/**
 * Interfaces
 */

interface MessageItemProps {
  message: Message;
  user: User;
  isCurrentUser: boolean;
  messageMultiple: MessageMultiple;
  showDateTimeHeader: boolean;
  isFirstMessage: boolean;
}

interface MessagesHistoryProps {
  messages?: Message[];
  currentUser: User;
  usersMap: UsersMap;
}

interface DisconnectionBannerProps {
  disconnected: boolean;
  reconnect: any;
}

interface BaseMessagesViewProps {
  circle: Circle;
  messages?: Message[];
  currentUser: User;
  jwt: string;
  usersMap: UsersMap;
  loadUsers: any;
  loadMessages: any;
  adminCircleListen: any;
  adminCircleUnlisten: any;
  socketSubs: string[];
}

interface MessagesViewProps extends BaseMessagesViewProps {
  createMessage: any;
  createMessageWithAttachment: any;
}

/**
 * Renders a single message to the chat
 */

const MessageItem: React.FC<MessageItemProps> = ({
  message,
  user,
  isCurrentUser,
  messageMultiple,
  showDateTimeHeader,
  isFirstMessage,
}: MessageItemProps) => {
  const time = new Date(message.created).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const isEvent = messageIsEvent(message);
  let sideContent: any;
  if (isEvent) {
    // Event gets event icon
    sideContent = (
      <span className={classes.event_icon}>
        <EventIcon />
      </span>
    );
  } else {
    if (isCurrentUser) {
      // current user gets spacer, for flex layout, to align content to right
      sideContent = <span className={classes.message_leftspacer} />;
    } else {
      // other users' messages get name and/or avatar
      sideContent = (
        <>
          {(!messageMultiple || messageMultiple === "many_first") && (
            <span className={classes.message_name}>{user.fullName}</span>
          )}
          {(!messageMultiple || messageMultiple === "many_first") && (
            <span className={classes.message_avatar} />
          )}
        </>
      );
    }
  }

  // TODO: Fix this so it requests the message with the audio file
  const fetchRemoteFile = (message: string) => {
    const token = localStorage.getItem("jwtToken");
    axios.get(message, {headers: {Authorization: `Bearer ${token}`}}).then((res) => {
      return res.data;
    });
    return undefined;
  };

  return (
    <div
      className={classNames(
        classes.message_row,
        classes[message.kind],
        message.urgency && classes[`event_urgent_${message.urgency}`],
        isCurrentUser && !isEvent && classes.current_user,
        messageMultiple && classes[messageMultiple]
      )}
    >
      {(showDateTimeHeader || isEvent) && (
        <span
          className={classNames(
            classes.day_header,
            // don't need HR for first message of day
            // don't (always) need HR for Events
            showDateTimeHeader && !isFirstMessage && classes.day_header_hr
          )}
        >
          {getShortDayString(message)} {time}
        </span>
      )}
      {sideContent}
      {message.kind === "attachment" && message.text !== undefined ? (
        <audio src={message.text} controls />
      ) : (
        <span className={classes.message}>{message.text}</span>
      )}

      {!isEvent && <span className={classes.message_time}>{time}</span>}
    </div>
  );
};

/**
 * Renders a list of chat messages
 */

const MessagesHistory: React.FC<MessagesHistoryProps> = ({
  messages,
  currentUser,
  usersMap,
}: MessagesHistoryProps) => {
  const [lastViewed, setLastViewed] = useState();
  const scrollable = useRef() as any;
  useEffect(() => {
    const latestId = messages && messages.length && messages[messages.length - 1]._id;
    if (latestId && !lastViewed !== latestId) {
      setLastViewed(latestId);
    }
  }, [messages]);
  useEffect(() => {
    // @todo - disable if user intentionally scrolls up,
    // and instead show a small alert, "new messages below".
    // @todo - reenable when user clicks "new messages below",
    // or scrolls back to bottom.
    if (scrollable.current) {
      scrollable.current.scrollTop = scrollable.current.scrollHeight;
    }
  }, [lastViewed]);
  return (
    <div className={classes.history} ref={scrollable}>
      {messages &&
        messages.map(
          (message, index) =>
            usersMap[message.userId] && (
              <MessageItem
                key={message._id}
                message={message}
                user={usersMap[message.userId]}
                isCurrentUser={message.userId === currentUser._id}
                messageMultiple={!messageIsEvent(message) && isMultiple(message, index, messages)}
                showDateTimeHeader={messageIsAfterTimeGap(message, index, messages)}
                isFirstMessage={index === 0}
              />
            )
        )}
    </div>
  );
};

/**
 * Renders the text input
 */

const MessageComposer = ({createMessage, createMessageWithAttachment}: any) => {
  const [text, setText] = useState("");
  const [error, setError] = useState();
  const [focused, _setFocused] = useState(false);

  const [blob, setBlob] = useState("");

  const textarea = useRef() as any;

  const submit = () => {
    if (text !== "") {
      createMessage(text)
        .then(() => {
          setText("");
        })
        .catch((error: string) => {
          setError(error);
        });
    }
    if (blob !== "") {
      axios({
        method: "get",
        url: blob, // blob url eg. blob:http://localhost:8000/e89c5d87-a634-4540-974c-30dc476825cc
        responseType: "blob",
      }).then((response) => {
        const blob = new Blob([response.data], {type: "audio/aac"});
        createMessageWithAttachment(blob)
          .then(() => {
            setBlob("");
          })
          .catch((error: string) => {
            //setError(error);
          });
      });
    }
    return;
  };
  const submitIfEnter = (e: any) => {
    if (e.key === "Enter") {
      if (!(e.ctrlKey || e.shiftKey)) {
        submit();
      } else {
        setText((t) => t + "\n");
      }
    }
  };
  const sendAction = (text: string) => {
    createMessage(text, {kind: MessageKind.actionButton}).catch((error: string) => {
      setError(error);
    });
  };
  const setFocused = (f: boolean) => {
    _setFocused(f);
    if (f && textarea.current) {
      textarea.current.focus();
    }
  };

  return (
    <div className={classNames(classes.composer, focused && classes.composerFocused)}>
      {blob !== "" ? (
        <div className={classes.blobarea}>
          <div className={classes.audioarea}>
            <audio src={blob} controls />
            <div className={classes.iconContainer} onClick={() => setBlob("")}>
              <CloseIcon className={classes.icons} />
            </div>
          </div>
        </div>
      ) : (
        <textarea
          ref={textarea}
          className={classes.textarea}
          onChange={(e) => setText(e.target.value)}
          onKeyUp={(e) => submitIfEnter(e)}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          value={text}
          placeholder="Start typing"
        />
      )}
      <input
        type="image"
        alt="Send"
        src={sendIcon}
        className={classes.sendButton}
        onClick={submit}
      />
      <div className={classes.actionButtons} onClick={() => setFocused(true)}>
        <span className={classNames(classes.current_user, classes.text)}>
          <span className={classes.message} onClick={() => sendAction("On My Way")}>
            On My Way
          </span>
        </span>
        <span className={classNames(classes.current_user, classes.text)}>
          <span className={classes.message} onClick={() => sendAction("Everything Is Okay")}>
            Everything Is Okay
          </span>
        </span>
        <ReactMediaRecorder
          audio
          blobPropertyBag={{type: "audio/aac"}}
          whenStopped={(url: string) => setBlob(url)}
          render={({
            status,
            startRecording,
            stopRecording,
            mediaBlob,
          }: {
            status: string;
            startRecording: any;
            stopRecording: any;
            mediaBlob: string;
          }) => (
            <div
              className={status !== "recording" ? classes.iconContainer : classes.redIconContainer}
              onClick={status !== "recording" ? startRecording : stopRecording}
            >
              {status !== "recording" ? (
                <PlayArrowIcon fontSize="large" className={classes.icons} />
              ) : (
                <StopIcon fontSize="large" className={classes.icons} />
              )}
            </div>
          )}
        />
      </div>
      <span className={classes.error}>{error}</span>
    </div>
  );
};

/**
 * Disconnection banner
 */

const DisconnectionBanner: React.FC<DisconnectionBannerProps> = ({
  disconnected,
  reconnect,
}: DisconnectionBannerProps) => {
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    if (!disconnected) {
      setShowBanner(false);
      return;
    }
    // Delay adding class for CSS transition
    const showBannerTimeout = setTimeout(() => {
      setShowBanner(true);
    }, 0);

    const reconnectInterval = setInterval(() => {
      reconnect();
    }, 1000);

    return () => {
      clearTimeout(showBannerTimeout);
      clearInterval(reconnectInterval);
    };
  }, [disconnected, showBanner]);
  return (
    <div
      className={classNames(classes.disconnectWarning, showBanner && classes.showDisconnectWarning)}
    >
      Disconnected from server... trying to reconnect in 3&hellip;2&hellip;1&hellip;
    </div>
  );
};
/**
 * Renders chat history and text input/action buttons
 */

const MessagesView: React.FC<MessagesViewProps> = ({
  circle,
  messages,
  currentUser,
  jwt,
  usersMap,
  loadUsers,
  loadMessages,
  createMessage,
  createMessageWithAttachment,
  adminCircleListen,
  adminCircleUnlisten,
  socketSubs,
}: MessagesViewProps) => {
  useEffect(() => {
    if (Object.keys(usersMap).length === 0) {
      loadUsers();
    }
    loadMessages();
  }, []);
  useEffect(() => {
    if (!circle.members.includes(currentUser._id)) {
      adminCircleListen(jwt, circle._id);
      return () => {
        adminCircleUnlisten(circle._id);
      };
    }
    return;
  }, [circle, currentUser]);
  const reconnect = () => {
    adminCircleListen(jwt, circle._id);
  };
  const createMessageWithAttachmentDetails = (data: any, options = {}) =>
    createMessageWithAttachment({circleId: circle._id, data, kind: "AUDIO", ...options});

  const createMessageWithDetails = (text: string, options = {}) =>
    createMessage({circleId: circle._id, userId: currentUser._id, text, ...options});

  return (
    <>
      <DisconnectionBanner disconnected={!socketSubs.includes(circle._id)} reconnect={reconnect} />
      <MessagesHistory messages={messages} currentUser={currentUser} usersMap={usersMap} />
      <MessageComposer
        createMessage={createMessageWithDetails}
        createMessageWithAttachment={createMessageWithAttachmentDetails}
      />
    </>
  );
};

const MessagesViewPromises: React.FC<BaseMessagesViewProps> = (props: BaseMessagesViewProps) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={MessagesActionTypes.CREATE_MESSAGE}
    resolve={MessagesActionTypes.CRUD_DONE}
    reject={MessagesActionTypes.CRUD_ERROR}
  >
    {(createMessagePromise: any) => (
      <MakeAsyncFunction
        listener={promiseListener}
        start={MessagesActionTypes.CREATE_MESSAGE_ATTACHMENT}
        resolve={MessagesActionTypes.CRUD_DONE}
        reject={MessagesActionTypes.CRUD_ERROR}
      >
        {(createMessageAttachmentPromise: any) => (
          <MessagesView
            createMessage={createMessagePromise}
            createMessageWithAttachment={createMessageAttachmentPromise}
            {...props}
          />
        )}
      </MakeAsyncFunction>
    )}
  </MakeAsyncFunction>
);

export default connect(
  (state: any, ownProps: any) => ({
    messages: getMessagesFor(state, ownProps.circle._id),
    currentUser: getCurrentUser(state),
    jwt: getUserJWT(state),
    usersMap: getUsersMap(state),
    socketSubs: getSocketSubscriptions(state),
  }),
  {
    loadUsers,
    loadMessages,
    adminCircleListen,
    adminCircleUnlisten,
  }
)(MessagesViewPromises);
