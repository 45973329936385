import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classNames from "classnames";

import MakeAsyncFunction from "react-redux-promise-listener";

import {promiseListener} from "../store/configureStore";
import {
  UsersManagerActionTypes,
  UsersManagerState,
  getUsersManagerUpsertErrors,
  clearUsersManagerUpsertErrors,
  getCurrentUser,
  UserState,
  User,
  Circle,
  register,
  UserActionTypes,
} from "../../../shared";

import {LinkCell} from "./UserList";
import UserForm, {FormState} from "./UserForm";
import PageHeader from "./PageHeader";

/**
 * Renders static user data
 */

interface UserDataProps {
  user: User;
}

const UserData: React.FC<UserDataProps> = ({user}: UserDataProps) => (
  <>
    <p>Phone number: {user.phoneNumber}</p>
    <p>Email: {user.email}</p>
    <p>Account type: {user.kind}</p>
  </>
);

/**
 * Renders static data about user's circles
 */

interface CircleDataProps {
  user: User;
  circles: Circle[];
}

const CircleList: React.FC<CircleDataProps> = ({user, circles}: CircleDataProps) => (
  <>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <th>Size</th>
        </tr>
      </thead>
      <tbody>
        {circles.map((circle) => (
          <tr key={circle._id}>
            <LinkCell to={"/circles/" + circle._id + "?lastViewed=" + user._id}>
              {circle.name}
            </LinkCell>
            <LinkCell to={"/circles/" + circle._id + "?lastViewed=" + user._id}>
              {circle.admins.includes(user._id) ? "Admin" : "Member"}
            </LinkCell>
            <LinkCell to={"/circles/" + circle._id + "?lastViewed=" + user._id}>
              {circle.members.length} members
            </LinkCell>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

/**
 * Parent component manages view state
 */

interface BaseUserViewProps {
  currentUser?: User;
  centerId?: string;
  user?: User;
  circles?: Circle[];
  newUser: boolean;
  newAdmin: boolean;
  errors?: string;
  clearUsersManagerUpsertErrors: any;
  history: any;
  register: (user: Partial<User>) => Promise<any>;
  cancelEditing?: () => void;
}

interface UserViewProps extends BaseUserViewProps {
  updateUser: any;
  registrationThenBootstrap: (user: any) => Promise<any>;
}

interface UserViewState {
  editing: boolean;
}

class UserView extends React.Component<UserViewProps, UserViewState> {
  constructor(props: UserViewProps) {
    super(props);
    this.state = {
      editing: props.newUser,
    };
  }

  handleSubmit = (userValues: FormState) => {
    if (this.props.newUser) {
      const promise =
        userValues.kind === "user"
          ? // Regular users have extra bootstrapping to be done
            this.props.registrationThenBootstrap({
              ...userValues,
              doLogin: false,
              // Creating new 'user' type member from FlightDeck is always a resident,
              // so enable safety features
              isSenior: true,
            })
          : // Just register admins
            this.props.register(userValues);
      return promise.then((res: any) => {
        this.props.history.replace("/users/" + res._id);
        this.resetForm();
      });
    } else {
      return this.props.updateUser(userValues).then(() => {
        this.resetForm();
      });
    }
  };

  resetForm = () => {
    this.props.clearUsersManagerUpsertErrors();
    if (this.props.cancelEditing) {
      this.props.cancelEditing();
    }
    this.setState({editing: false});
  };

  render() {
    const {currentUser, newUser, newAdmin, user, circles, errors} = this.props;
    const {editing} = this.state;
    let title = "";

    if (newUser) {
      title = "Create New " + (newAdmin ? "Staff" : "Member");
    } else if (user) {
      title = user.fullName;
    }

    return (
      <>
        <div className="clearfix">
          {!editing && (
            <PageHeader action={"Edit"} actionOnClick={() => this.setState({editing: true})}>
              {title}
            </PageHeader>
          )}
        </div>

        {editing && errors && <p>Error! {errors}</p>}
        {!editing && user && (
          <>
            <h2>Basic information</h2>
            <UserData user={user} />
          </>
        )}
        {editing && currentUser && (
          <UserForm
            currentUser={currentUser}
            user={user}
            centerId={this.props.centerId || currentUser.centerId}
            submitForm={this.handleSubmit}
            resetForm={this.resetForm}
            isAdmin={newAdmin}
          />
        )}
        {!editing && user && circles && (
          <>
            <div className="clearfix">
              <h2 className="float-left">Circles</h2>
              <Link
                className={classNames("button", "button-outline", "float-right")}
                to={"/circles/new?lastViewed=" + user._id}
              >
                Add New Circle
              </Link>
            </div>
            <CircleList user={user} circles={circles} />
          </>
        )}
      </>
    );
  }
}

const UserViewPromises: React.FC<BaseUserViewProps> = (props: BaseUserViewProps) => (
  <MakeAsyncFunction
    listener={promiseListener}
    start={UsersManagerActionTypes.UPDATE_USER}
    resolve={UsersManagerActionTypes.UPSERT_DONE}
    reject={UsersManagerActionTypes.UPSERT_ERROR}
  >
    {(updateUserPromise: any) => (
      <MakeAsyncFunction
        listener={promiseListener}
        start={UserActionTypes.APP_REGISTRATION}
        resolve={UserActionTypes.APP_REGISTRATION_DONE}
        reject={UserActionTypes.APP_REGISTRATION_ERR}
      >
        {(appRegistrationPromise: any) => (
          <UserView
            updateUser={updateUserPromise}
            registrationThenBootstrap={appRegistrationPromise}
            {...props}
          />
        )}
      </MakeAsyncFunction>
    )}
  </MakeAsyncFunction>
);

export default connect(
  (state: {users: UsersManagerState; user: UserState}) => ({
    errors: getUsersManagerUpsertErrors(state),
    currentUser: getCurrentUser(state),
  }),
  {
    clearUsersManagerUpsertErrors,
    register,
  }
)(UserViewPromises);
