import {all, takeEvery, select} from "redux-saga/effects";
import {loginSaga, logoutSaga} from "./auth";
import {centersSaga} from "./centers";
import {bootstrapAppSaga} from "./app";
import {
  userManagerCrudSaga,
  circlesUpsertSaga,
  circlesLoadSaga,
  circleDeleteSaga,
  messagesLoadSaga,
  messagesUpsertSaga,
  messageDeleteSaga,
  connectSocketsSaga,
  userSaga,
  eventHandlerCreateDefaultSaga,
  eventHandlersUpsertSaga,
  eventHandlerDeleteSaga,
  eventHandlersLoadSaga,
} from "../../../shared";

const LOGGING_ENABLED = true;

function* loggerAction(action: any) {
  const state = yield select((x) => x);
  console.log("[ACTION]", action.type, action.payload, state);
  return undefined;
}

function* loggerSaga() {
  if (LOGGING_ENABLED) {
    yield takeEvery("*", loggerAction);
  }
}

export default function* rootSaga() {
  yield all([
    bootstrapAppSaga(),
    connectSocketsSaga(),
    loginSaga(),
    logoutSaga(),
    userManagerCrudSaga(),
    circlesLoadSaga(),
    circlesUpsertSaga(),
    circleDeleteSaga(),
    messagesLoadSaga(),
    messagesUpsertSaga(),
    messageDeleteSaga(),
    centersSaga(),
    userSaga(),
    eventHandlersLoadSaga(),
    eventHandlersUpsertSaga(),
    eventHandlerDeleteSaga(),
    eventHandlerCreateDefaultSaga(),
    loggerSaga(),
  ]);
}
