import React from "react";
import {connect} from "react-redux";
import {login, register, User, UserState, getCurrentUser, getUserErrors} from "../../../shared";
import Button from "./Button";
import classes from "./AuthForm.module.css";

interface Props {
  login: any;
  register: any;
  user?: User;
  errors?: string;
}

interface LoginState {
  email: string;
  password: string;
}

class LoginForm extends React.Component<Props, LoginState> {
  state = {
    email: "",
    password: "",
  };

  onSubmit = async (e: any) => {
    e.preventDefault();
    await this.props.login(this.state.email, this.state.password);
  };

  render() {
    return (
      <div className={classes.container}>
        <h1>Log In</h1>
        <form onSubmit={this.onSubmit}>
          <label>
            Email
            <input
              type="email"
              name="email"
              autoComplete="email"
              value={this.state.email}
              onChange={(e) => this.setState({email: e.target.value})}
            />
          </label>
          <label>
            Password
            <input
              type="password"
              name="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={(e) => this.setState({password: e.target.value})}
            />
          </label>
          {this.props.errors && <p className="color-red-5">{this.props.errors}</p>}
          <Button type="submit">Log in</Button>
        </form>
      </div>
    );
  }
}

export default connect(
  (state: {user: UserState}) => ({
    user: getCurrentUser(state),
    errors: getUserErrors(state),
  }),
  {
    login,
  }
)(LoginForm as any);
