import {applyMiddleware, combineReducers, createStore, compose} from "redux";
import {centersReducer} from "./Centers";
import {socketsMiddleware} from "../../../shared";
import rootSaga from "../sagas";
import {
  userReducer,
  usersManagerReducer,
  circlesReducer,
  messagesReducer,
  socketsReducer,
  eventHandlersReducer,
} from "../../../shared";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import createReduxPromiseListener from "redux-promise-listener";

const reducers = combineReducers({
  user: userReducer,
  users: usersManagerReducer,
  centers: centersReducer,
  circles: circlesReducer,
  messages: messagesReducer,
  sockets: socketsReducer,
  eventHandlers: eventHandlersReducer,
});

const sagaMiddleware = createSagaMiddleware();
export const promiseListener = createReduxPromiseListener();

const middleware = applyMiddleware(
  socketsMiddleware,
  thunk,
  sagaMiddleware,
  promiseListener.middleware
);
const composeEnhancers = (window as any)["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;

export const store = createStore(reducers, composeEnhancers(middleware));

sagaMiddleware.run(rootSaga);
