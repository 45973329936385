"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var UserStore_1 = require("./UserStore");

var CirclesStore_1 = require("./CirclesStore");

exports.getSafetyFeaturesEnabled = function (state) {
  var user = UserStore_1.getCurrentUser(state);

  if (!user) {
    console.warn("Checking for saftey features with no user.");
    return false;
  }

  var circle = CirclesStore_1.getCirclesFor(state, user._id);

  if (!circle || circle.length !== 1) {
    console.warn("Checking for saftey features with no circle.");
    return false;
  }

  return circle[0].seniors.some(function (id) {
    return id === user._id;
  });
};