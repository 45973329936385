"use strict";
/**
 * CrudAPI
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Users
 */

var UserKind;

(function (UserKind) {
  UserKind["superAdmin"] = "superAdmin";
  UserKind["admin"] = "admin";
  UserKind["staff"] = "staff";
  UserKind["user"] = "user";
})(UserKind = exports.UserKind || (exports.UserKind = {}));
/**
 * Chat messages & sockets
 */


var MessageKind;

(function (MessageKind) {
  MessageKind["text"] = "text";
  MessageKind["actionButton"] = "actionButton";
  MessageKind["event"] = "event";
  MessageKind["attachment"] = "attachment";
})(MessageKind = exports.MessageKind || (exports.MessageKind = {}));

var MessageActionType;

(function (MessageActionType) {
  // These are just informational
  MessageActionType["onTheWay"] = "onTheWay";
  MessageActionType["confirmIsEmergency"] = "confirmIsEmergency"; // These mark the ActivityEvent as "resolved"

  MessageActionType["resolved"] = "resolved";
  MessageActionType["resolvedNonEmergency"] = "resolvedNonEmergency";
})(MessageActionType = exports.MessageActionType || (exports.MessageActionType = {}));

var SocketEvents;

(function (SocketEvents) {
  SocketEvents["JoinCircle"] = "JOIN_CIRCLE";
  SocketEvents["LeaveCircle"] = "LEAVE_CIRCLE";
  SocketEvents["SubscriptionsList"] = "SUBS"; // Chat messages

  SocketEvents["NewMessage"] = "NEW_MESSAGE";
  SocketEvents["CreateMessage"] = "CREATE_MESSAGE";
  SocketEvents["CreateMessageAttachment"] = "CREATE_MESSAGE_ATTACHMENT";
  SocketEvents["CrudDone"] = "CRUD_DONE";
  SocketEvents["CrudError"] = "CRUD_ERROR"; // Circles

  SocketEvents["CircleUpdated"] = "CIRCLE_UPDATED";
})(SocketEvents = exports.SocketEvents || (exports.SocketEvents = {}));

var EventUrgency;

(function (EventUrgency) {
  EventUrgency["high"] = "high";
  EventUrgency["medium"] = "medium";
  EventUrgency["low"] = "low";
})(EventUrgency = exports.EventUrgency || (exports.EventUrgency = {}));

var EventCategory;

(function (EventCategory) {
  EventCategory["geo"] = "geo";
  EventCategory["fall"] = "fall";
  EventCategory["button"] = "button";
})(EventCategory = exports.EventCategory || (exports.EventCategory = {}));

var EventType;

(function (EventType) {
  EventType["enter"] = "enter";
  EventType["exit"] = "exit";
  EventType["inside"] = "inside";
  EventType["outside"] = "outside"; // A fall where the timer starts and finishes without user interaction

  EventType["fallTimeout"] = "fallTimeout"; // A fall where the user falls and hits a "help now" button

  EventType["fallConfirmed"] = "fallConfirmed"; // A fall was detected but the user is ok

  EventType["fallButOk"] = "fallButOk";
  EventType["fallDroppedDevice"] = "fallDroppedDevice";
  EventType["fallFalseAlert"] = "fallFalseAlert";
  EventType["arrivedEarlier"] = "arrivedEarlier";
  EventType["arrivedOnTime"] = "arrivedOnTime";
  EventType["lateForAppointment"] = "lateForAppointment";
  EventType["arrivedLate"] = "arrivedLate";
  EventType["missedAppointment"] = "missedAppointment";
  EventType["leftEarly"] = "leftEarly";
  EventType["leftOnTime"] = "leftOnTime";
  EventType["hasNotLeft"] = "hasNotLeft";
  EventType["leftLate"] = "leftLate";
  EventType["helpButtonConfirmed"] = "helpButtonConfirmed";
  EventType["helpButtonTimeout"] = "helpButtonTimeout";
  EventType["helpButtonButOk"] = "helpButtonButOk";
  EventType["helpButtonFalseAlert"] = "helpButtonFalseAlert"; // These are for "data point" logging

  EventType["location"] = "location";
  EventType["batteryLevel"] = "batteryLevel";
  EventType["batteryLevelLow"] = "batteryLevelLow"; // These don't create EventLogs, they go straight to ActivityEvents

  EventType["circleCreated"] = "circleCreated";
  EventType["circleMemberJoined"] = "circleMemberJoined";
  EventType["circleMemberLeft"] = "circleMemberLeft";
})(EventType = exports.EventType || (exports.EventType = {})); // A list of all event types. Useful for Mongoose schema enums.


exports.EVENT_TYPES = Object.values(EventType);
exports.GEO_LOCATION_EVENT_TYPES = [EventType.enter, EventType.exit, EventType.inside, EventType.outside];
exports.APPOINTMENT_EVENT_TYPES = [EventType.arrivedEarlier, EventType.arrivedOnTime, EventType.lateForAppointment, EventType.arrivedLate, EventType.missedAppointment, EventType.leftEarly, EventType.leftOnTime, EventType.hasNotLeft, EventType.leftLate];
exports.FALL_EVENT_TYPES = [EventType.fallTimeout, EventType.fallConfirmed, EventType.fallButOk, EventType.fallDroppedDevice, EventType.fallFalseAlert];
exports.BUTTON_EVENT_TYPES = [EventType.helpButtonConfirmed, EventType.helpButtonTimeout, EventType.helpButtonButOk, EventType.helpButtonFalseAlert];
exports.CIRCLE_EVENT_TYPES = [EventType.circleCreated, EventType.circleMemberJoined, EventType.circleMemberLeft];