import {AnyAction} from "redux";
import {Center, UserActionTypes} from "../../../shared";

/**
 * Types
 */

export interface CentersMap {
  [id: string]: Center;
}

export interface CentersState {
  centersMap: CentersMap;
  centersLoading: boolean;
  centersLoadingError?: string;
  upsertErrors?: string;
}

export enum CentersActionTypes {
  LOAD_CENTER = "@@centers/LOAD_CENTER",
  LOAD_CENTERS = "@@centers/LOAD_CENTERS",
  LOADING_ERROR = "@@centers/LOADING_ERROR",
  CENTERS_LOADED = "@@centers/CENTERS_LOADED",
  UPSERT_CENTER = "@@centers/UPSERT_CENTER",
  UPSERT_ERROR = "@@centers/UPSERT_ERROR",
  UPSERT_DONE = "@@centers/UPSERT_DONE",
}

/**
 * Reducer
 */

const initialState: CentersState = {
  centersMap: {},
  centersLoading: false,
  centersLoadingError: undefined,
  upsertErrors: undefined,
};

export function centersReducer(
  state: CentersState = initialState,
  action: AnyAction
): CentersState {
  switch (action.type) {
    case CentersActionTypes.LOAD_CENTERS: {
      return {
        ...state,
        centersLoading: true,
        centersLoadingError: undefined,
      };
    }
    case CentersActionTypes.CENTERS_LOADED: {
      return {
        ...state,
        centersLoading: false,
        centersMap: action.payload,
      };
    }
    case CentersActionTypes.LOADING_ERROR: {
      return {
        ...state,
        centersLoading: false,
        centersLoadingError: action.payload,
      };
    }
    case UserActionTypes.LOG_OUT: {
      return initialState;
    }
    default:
      return state;
  }
}

/**
 * Actions
 */

export const loadCenters = () => ({
  type: CentersActionTypes.LOAD_CENTERS,
});

export const loadCenter = (centerId: string) => ({
  type: CentersActionTypes.LOAD_CENTER,
  payload: centerId,
});

export const centersLoaded = (centers: CentersMap) => ({
  type: CentersActionTypes.CENTERS_LOADED,
  payload: centers,
});

export const upsertCenter = (center: Partial<Center>) => ({
  type: CentersActionTypes.UPSERT_CENTER,
  payload: center,
});

/**
 * Selectors
 */

export function selectCenters(state: {centers: CentersState}) {
  return Object.values(state.centers.centersMap) || [];
}

export function selectCentersMap(state: {centers: CentersState}) {
  return state.centers.centersMap;
}

export function selectUpsertCenterErrors(state: {centers: CentersState}) {
  return state.centers.upsertErrors;
}

export function selectCenter(state: {centers: CentersState}, id: string) {
  return state.centers.centersMap[id];
}
