import {takeLatest, call, put, select} from "redux-saga/effects";
import {CentersActionTypes, centersLoaded, CentersMap, selectCentersMap} from "../store/Centers";
import {getAuthedClient, crudApi, getCrudErrorMessage} from "../../../shared";

function* mergeCenters(newMap: CentersMap) {
  let existingMap: CentersMap = yield select(selectCentersMap);
  yield put(centersLoaded({...existingMap, ...newMap}));
}

function* loadCenterAction(action: any) {
  // // https://github.com/jfairbank/redux-saga-test-plan/issues/237#issuecomment-485377197
  const authedClient = yield put(yield call(getAuthedClient));
  if (!authedClient) {
    yield put({type: CentersActionTypes.LOADING_ERROR, payload: "Not authorized."});
    return;
  }

  const centersApi = yield call(crudApi, authedClient, "centers");

  try {
    const center = yield call(centersApi.read, action.payload);
    let centersMap: CentersMap = {};
    console.log("[[MEOW", center);

    centersMap[center._id] = center;
    yield call(mergeCenters, centersMap);
  } catch (e) {
    console.error(e);
    yield put({type: CentersActionTypes.LOADING_ERROR, payload: getCrudErrorMessage(e)});
    return;
  }
}

function* loadCentersAction() {
  // // https://github.com/jfairbank/redux-saga-test-plan/issues/237#issuecomment-485377197
  const authedClient = yield put(yield call(getAuthedClient));
  if (!authedClient) {
    yield put({type: CentersActionTypes.LOADING_ERROR, payload: "Not authorized."});
    return;
  }

  const centersApi = yield call(crudApi, authedClient, "centers");

  try {
    const apiResponse = yield call(centersApi.list, {limit: 100});
    let centersMap: CentersMap = {};

    for (let center of apiResponse.data) {
      centersMap[center._id] = center;
    }
    yield call(mergeCenters, centersMap);
  } catch (e) {
    yield put({type: CentersActionTypes.LOADING_ERROR, payload: getCrudErrorMessage(e)});
    return;
  }
}

function* upsertCenterAction(action: any) {
  // https://github.com/jfairbank/redux-saga-test-plan/issues/237#issuecomment-485377197
  const authedClient = yield put(yield call(getAuthedClient));
  if (!authedClient) {
    yield put({type: CentersActionTypes.UPSERT_ERROR, payload: "Not authorized."});
    return;
  }

  const centersApi = yield call(crudApi, authedClient, "centers");
  const values = {
    name: action.payload.name,
    email: action.payload.email,
    address: action.payload.address,
    phoneNumber: action.payload.phoneNumber,
  } as any;
  let response;

  try {
    if (action.payload._id) {
      response = yield call(centersApi.update, action.payload._id, values);
      if (!response) {
        response = {
          ...values,
          _id: action.payload._id,
        };
      }
    } else {
      response = yield call(centersApi.create, values);
    }
  } catch (e) {
    yield put({type: CentersActionTypes.UPSERT_ERROR, payload: getCrudErrorMessage(e)});
    return;
  }

  yield put({type: CentersActionTypes.UPSERT_DONE, payload: response});
  yield call(mergeCenters, {[response._id]: response});
}

export function* centersSaga() {
  yield takeLatest(CentersActionTypes.LOAD_CENTER, loadCenterAction);
  yield takeLatest(CentersActionTypes.LOAD_CENTERS, loadCentersAction);
  yield takeLatest(CentersActionTypes.UPSERT_CENTER, upsertCenterAction);
}
